const colors = {
    light: {
        primary: {
            '200': '#909ab0',
            '800': '#132145',
            main: '#203461',
            light: '#e4e7ec',
            dark: '#1c2f59'
        },
        secondary: {
            '200': '#f6a0bd',
            '800': '#9a182c',
            main: '#c10230',
            light: '#fde8ef',
            dark: '#aa182c'
        }
    },
    dark: {
        primary: {
            '200': '#b0b6c4',
            '800': '#44506b',
            main: '#606d88',
            light: '#ecedf1',
            dark: '#586580'
        },
        secondary: {
            '200': '#f6a0bd',
            '800': '#e42a5d',
            main: '#ec407a',
            light: '#fde8ef',
            dark: '#ea3a72'
        }
    }
};

export default colors;
